import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const NewMenberCasino1 = () => (
  <State>
    <Hilight>
      <ImageResponsive
       source="10-11-2021-casino2.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>สมาชิกใหม่คาสิโน ฝากน้อย แต่โบนัสเยอะ!</HeadiingTitle>

        <HeadiingSubtitle>
          &quot; โปรโมชั่น ฝาก 50 รับ 75 &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'รายละเอียด'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'Details'}
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            ฝาก 50 รับโบนัส 25
            ยอดรวมเครดิตที่ได้รับ 75บาท
            ทำยอดชนะ 5 เท่า สามารถถอนได้สูงสุด 500 บาท
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'How to calculate turnover'}
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            ยอดฝาก = 50 บาท
            ยอดโบนัส = 25 บาท
            ยอดชนะที่ต้องการ = (50 + 25 ) x 5 = 375 บาท (ไม่รวมทุน)
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'Terms and conditions'}
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>โปรโมชั่นนี้สำหรับสมาชิกใหม่เท่านั้น</TermList>
          <TermList>โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 50 บาท จึงจะได้รับโบนัส</TermList>
          <TermList>สมาชิกสามารถเลือกรับโบนัสผ่านระบบได้ด้วยตนเอง</TermList>
          <TermList>สมาชิกต้องทำยอดเทิร์นโอเวอร์หรือทำยอดชนะตามเงื่อนไขโปรโมชั่นจึงจะสามารถถอนได้</TermList>
          <TermList>โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆได้</TermList>
          <TermList>โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ Alltop Asia เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที</TermList>
          <TermList>หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์หรือการทำยอดชนะ สามารถเลือกไม่รับโปรโมชั่นได้</TermList>
          <TermList>หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์หรือทำยอดชนะครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด</TermList>
          <TermList>กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์หรือทำยอดชนะผิดปกติ Alltop Asia ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที</TermList>
          <TermList>Alltop Asia ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</TermList>
          <TermList>ทุกโปรโมชั่นของ Alltop Asia จำกัดสำหรับ 1 บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP แอดเดรสเดียวเท่านั้น</TermList>
          <TermList>หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก จึงจะสามารถทำรายการถอนได้</TermList>
          <TermList>การตัดสินของ Alltop Asia ถือเป็นที่สิ้นสุด</TermList>
        </TermDesc>
      </Term>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => (currentLanguage === 'th' ? 'หมายเหตุ: ' : 'Note: ')}
          </IntlContextConsumer>
          สมาชิกต้องเดิมพันขั้นต่ำ 20 บาท จำนวน 10 บิลขึ้นไป ถึงจะผ่านเงื่อนไข
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && (
            <ButtonTheme
              to="/promotions"
              icon="promotion"
              title="โปรโมชั่นทั้งหมด"
              type="silver"
            />
            )}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && (
            <ButtonTheme
              to="/promotions"
              icon="promotion"
              title="More Promotions"
              type="silver"
            />
            )}
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default NewMenberCasino1;
