import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailDescContentLink,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <IntlContextConsumer>
        {({ language: currentLanguage }) =>
          currentLanguage === 'th' && (
            <ImageResponsive
              source="pragmatic-th.png"
              alt="ALLTOP ASIA Promotions"
              fadeIn
              placeholder
            />
          )
        }
      </IntlContextConsumer>

      <IntlContextConsumer>
        {({ language: currentLanguage }) =>
          currentLanguage === 'en' && (
            <ImageResponsive
              source="pragmatic-en.png"
              alt="ALLTOP ASIA Promotions"
              fadeIn
              placeholder
            />
          )
        }
      </IntlContextConsumer>
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'Pragmatic Play Daily wins Cash Drop'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Pragmatic Play Daily wins Cash Drop'
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'ALLTOP Affiliate เครือข่ายตัวแทนหุ้นลม ALLTOP ASIA'
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'ALLTOP Affiliate เครือข่ายตัวแทนหุ้นลม ALLTOP ASIA'
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>
      {/* 
      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' && (
                  <>
                    <h2>
                      เข้าร่วมเป็นครอบครัวกับเรา ได้ง่ายๆที่:{' '}
                      <DetailDescContentLink
                        href="https://www.alltopasia.com/affiliate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ALLTOP AFFILIATE
                      </DetailDescContentLink>
                    </h2>

                    <h3>ด้วยข้อเสนอสุดพิเศษกว่าใคร! ผลประโยชน์ 4 เด้ง</h3>

                    <p>
                      เด้งที่ 1 : รับส่วนแบ่งกำไรสูงสุด 50% <br />
                      เด้งที่ 2 : เงินประจำตำแหน่ง <br />
                      เด้งที่ 3 : สมาชิกภายใต้ฝากครั้งแรก 300 บาท รับทันที 100
                      บาท <br />
                      เด้งที่ 4 : รับส่วนแบ่งจากเครือข่ายพันธมิตร 5% <br />
                      <br />
                      ส่วนแบ่งรายได้สูง และเลื่อนขั้นง่ายที่สุดในตลาด
                    </p>
                  </>
                )
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' && (
                  <>
                    <h2>
                      เข้าร่วมเป็นครอบครัวกับเรา ได้ง่ายๆที่:{' '}
                      <DetailDescContentLink
                        href="https://www.alltopasia.com/affiliate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ALLTOP AFFILIATE
                      </DetailDescContentLink>
                    </h2>

                    <h3>ด้วยข้อเสนอสุดพิเศษกว่าใคร! ผลประโยชน์ 4 เด้ง</h3>

                    <p>
                      เด้งที่ 1 : รับส่วนแบ่งกำไรสูงสุด 50% <br />
                      เด้งที่ 2 : เงินประจำตำแหน่ง <br />
                      เด้งที่ 3 : สมาชิกภายใต้ฝากครั้งแรก 300 บาท รับทันที 100
                      บาท <br />
                      เด้งที่ 4 : รับส่วนแบ่งจากเครือข่ายพันธมิตร 5% <br />
                      <br />
                      ส่วนแบ่งรายได้สูง และเลื่อนขั้นง่ายที่สุดในตลาด
                    </p>
                  </>
                )
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail> */}

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>
            เดิมพันขั้นต่ำในการเข้าร่วมโปรโมชั่นคือ ฿ 10 หรือเทียบเท่าในสกุลเงิน
          </TermList>
          <TermList>
            เกมที่เข้าร่วม tournament: เกมทั้งหมดของค่าย Pragmatic Play ยกเว้น
            Money Roll, Irish Charms, 888 Gold, Diamonds are Forever 3 lines,
            Queen of Gold, Table Games
          </TermList>
          <TermList>
            เกมที่สามารถร่วม Cash Drop ได้ : เกมทั้งหมดของค่าย Pragmatic Play
            ยกเว้น เกมโต๊ะทั้งหมด วิดีโอโป๊กเกอร์ทั้งหมด Money Roll,Irish
            Charms,888 Gold,Diamonds are Forever 3 Lines,Keno,Queen of
            Gold,KTV,Hockey League,Aladdin’s Treasure ,Lady Of The Moon,Tales Of
            Egypt,Sugar Rush,Sugar Rush Winter,Sugar Rush Valentine’s Day,Sugar
            Rush Summer Time,Spooky Fortune,Great Reef,Glorious Rome,Fruity
            Blast,Dwarven Gold,Devil’s 13,Crazy 7s,Busy Bees
          </TermList>
          <TermList>
            ผู้เล่นสามารถรับรางวัลทั้งจาก Tournament และ Cash Drop ได้
          </TermList>
          <TermList>
            หากมีผู้เล่นสองคนหรือมากกว่าที่มีคะแนนเท่ากันในลีดเดอร์บอร์ดของทัวร์นาเมนต์ผู้เล่นที่ทำคะแนนได้ก่อนจะได้รับตำแหน่งที่สูงกว่าในลีดเดอร์บอร์ดของเรา
          </TermList>
          <TermList>
            ลีดเดอร์บอร์ดของการแข่งขันที่สร้างขึ้นในเกมเป็นฟีเจอร์พิเศษของเราที่อัปเดตคะแนนแบบเรียลไทม์
          </TermList>
          <TermList>
            Pragmatic Play
            ขอสงวนสิทธิ์ในการแก้ไขระงับหรือยกเลิกโปรโมชั่นได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>
        </TermDesc>
      </Term>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
