import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const PromotionDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="banner-alltopasia-promotion-cover--14%-unlimited-slot-game-bashback.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>14% Unlimited Slot&Game CashBack</HeadiingTitle>

        <HeadiingSubtitle>
          &quot; คืนยอดเสียสูงสุด 14% รายสัปดาห์ไม่จำกัด! ไม่ต้องทำเทิร์น!
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            สามารถรับยอดเงินคืนได้ที่ Topspin
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      {/* <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            SA Gaming, Sexy Baccarat, WM Casino, Evolution Gaming, Gclub
          </DetailDescContent>
        </DetailDesc>
      </Detail> */}

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            ไม่มีการทำเทิร์นโอเวอร์หรือการทำยอดกำไรใดๆ ทั้งสิ้น
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินกับ ALLTOP ASIA แล้วอย่างน้อย
            1 ครั้ง จึงจะมีคุณสมบัติรับโปรโมชั่นนี้
          </TermList>
          <TermList>
            สามารถทำการถอนได้เลย ไม่มีการทำเทิร์นโอเวอร์หรือการทำยอดกำไรใดๆ
            ทั้งสิ้น
          </TermList>
          <TermList>
            สมาชิกไม่ต้องขอรับโปรโมชั่นนี้
            ระบบจะทำการคืนยอดเสียให้อัตโนมัติทุกสัปดาห์
          </TermList>
          <TermList>
            สมาชิกจะได้รับยอดเงินคืนทาง Topspin ในวันศุกร์ของทุกสัปดาห์
            ตั้งแต่เวลา 15.00 น. ตามเวลาประเทศไทย เป็นต้นไป
          </TermList>
          <TermList>
            เงินจะเข้า กระเป๋า(Wallet) ของสมาชิกทันทีหลังจากการปั่น Topspin
          </TermList>
          <TermList>
            ALLTOP ASIA
            จะคำนวนยอดเงินคืนที่มีผลของการชนะหรือแพ้ที่แน่นอนแล้วเท่านั้น
          </TermList>
          <TermList>โปรโมชั่นนี้สามารถใช้ร่วมกับโปรโมชั่นอื่นๆได้</TermList>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ ALLTOP ASIA
            เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
            รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
          </TermList>
          <TermList>
            กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ ALLTOP
            ASIA ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
          </TermList>
          <TermList>
            ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
            ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>
          <TermList>
            ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
            บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
            แอดเดรสเดียวเท่านั้น
          </TermList>
          <TermList>การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด</TermList>
        </TermDesc>
      </Term>

      {/* <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' ? 'หมายเหตุ: ' : 'Note: '
            }
          </IntlContextConsumer>
          สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น
        </NoteContent>
      </Note> */}

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionDetail;
