import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const PromotionDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-welcome-combo-slot-300%.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>Welcome Combo Slot 300%</HeadiingTitle>

        <HeadiingSubtitle>
          &quot; Welcome Combo Slot 300% สูงสุด 3,000 บาท &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            Combo 1 รับโบนัส 50% สูงสุด 1,000 บาท ทำยอดชนะ 4 เท่า
            <br />
            Combo 2 รับโบนัส 100% สูงสุด 1,000 บาท ทำยอดชนะ 8 เท่า
            <br />
            Combo 3 รับโบนัส 150% สูงสุด 1,000 บาท ทำยอดชนะ 15 เท่า
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            Joker, 3win8, Gaming Soft, Playtech, Gi998, Slotciti, JDB, Pragmatic
            Play, CQ9, Habanero, Microgaming, Spadegaming, Play'n Go , PGSOFT,
            Simple Play, Ameba 918kiss, Pussy888, Mega888, XE88
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            ยอดฝาก + โบนัสที่ได้รับ x ยอดชนะของเเต่ละ Combo = ยอดที่สามารถถอนได้
            <br />
            ตัวอย่างการคำนวณ ยอดฝาก 500 + โบนัสCombo 1 50% (250) = 750 x ยอดชนะ
            4 เท่า = 3,000 ต้องมียอดเงินตามที่กำหนดถึงจะสามารถถอนได้
            <br />
            ตัวอย่างการคำนวณ ยอดฝาก 500 + โบนัสCombo 2 100% (500) = 1,000 x
            ยอดชนะ 8 เท่า = 8,000 ต้องมียอดเงินตามที่กำหนดถึงจะสามารถถอนได้
            <br />
            ตัวอย่างการคำนวณ ยอดฝาก 500 + โบนัส Combo 3 150% (1,000) = 1,500 x
            ยอดชนะ 15 เท่า = 22,500 ต้องมียอดเงินตามที่กำหนดถึงจะสามารถถอนได้
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>โปรโมชั่นนี้สำหรับสมาชิกใหม่เท่านั้น</TermList>

          <TermList>
            โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัสได้
          </TermList>

          <TermList>สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง</TermList>

          <TermList>
            เดิมพันได้เฉพาะประเภทสล็อตเท่านั้น
            (ห้ามเดิมพันคาสิโนสดและยิงปลาหรือเกมส์โต๊ะทุกชนิด
            หากตรวจพบทางเราจะให้ถอนทุกกรณี)
          </TermList>

          <TermList>
            การทำยอดกำไรจะนับจากยอดเดิมพันสล็อต,เกมส์และเกมส์คาสิโนเท่านั้น
            หากได้รับแจ็คพ็อต,อั่งเปา(ซองแดง)
            หรือยอดที่ไม่ได้มาจากการเดิมพันจะไม่ถูกนำไปคิดยอดการทำกำไรทุกกรณี
          </TermList>

          <TermList>
            หากได้รับ อั่งเปา (ซองแดง) ,แจ็คพ็อต
            แต่ยอดกำไรยังไม่ถึงตามที่กำหนดสมาชิกจะต้องทำยอดกำไรให้ถึงตามที่กำหนด
            จึงจะสามารถทำรายการถอนได้
          </TermList>

          <TermList>โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้</TermList>

          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ ALLTOP ASIA
            เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
            รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
          </TermList>

          <TermList>
            หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์
            สามารถเลือกไม่รับโปรโมชั่นได้
          </TermList>

          <TermList>
            หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
            ใดๆได้จนกว่าจะทำยอดชนะครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
          </TermList>

          <TermList>
            กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ ALLTOP
            ASIA ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
          </TermList>

          <TermList>
            ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
            ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>

          <TermList>
            ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
            บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
            แอดเดรสเดียวเท่านั้น
          </TermList>

          <TermList>
            หากสมาชิกทำรายการฝากเงินโดยไม่รับโปรโมชั่น
            สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าจึงจะสามารถทำรายการถอนได้
          </TermList>

          <TermList>การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด</TermList>
        </TermDesc>
      </Term>

      {/*
      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' ? 'หมายเหตุ: ' : 'Note: '
            }
          </IntlContextConsumer>
          สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น
        </NoteContent>
      </Note>
      */}

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionDetail;
