import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const PromotionDetail16 = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="banner-alltopasia-promotion-cover--1%-unlimited-casino-rebate.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>1% Unlimited Casino Rebate</HeadiingTitle>

        {/* <HeadiingSubtitle>
          &quot; คืนยอดเสียสูงสุด 6.5% รายสัปดาห์ไม่จำกัด! ไม่ต้องทำเทิร์น!
          &quot;
        </HeadiingSubtitle> */}
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            สามารถรับยอดเงินคืนได้ที่ Topspin
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            ทุกเว็บในเครือ ALLTOP ASIA ยกเว้นเว็บ 918Kiss และ Pussy888
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            สมาชิกจะได้รับค่าคอมมิชชั่นจากยอดเทิร์นโอเวอร์ 1% เช่น
            สมาชิกมียอดเทิร์นโอเวอร์ 100,000 x 1% = ยอดที่จะได้รับ
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินกับ ALLTOP ASIA แล้วอย่างน้อย
            1 ครั้ง จึงจะมีคุณสมบัติรับโปรโมชั่นนี้
          </TermList>
          <TermList>
            สามารถทำการถอนได้เลย ไม่มีการทำเทิร์นโอเวอร์หรือการทำยอดกำไรใดๆ
            ทั้งสิ้น
          </TermList>
          <TermList>
            สมาชิกไม่ต้องขอรับโปรโมชั่นนี้
            ระบบจะทำการคืนค่าคอมมิชชั่นให้อัตโนมัติในทุกวัน
          </TermList>
          <TermList>
            สมาชิกจะได้กดรับค่าคอมมิชชั่นทาง Topspin ได้ทุกวัน ตั้งแต่เวลา 16.00
            น. ตามเวลาประเทศไทย เป็นต้นไป
          </TermList>
          <TermList>
            สมาชิกจะสามารถกดรับค่าคอมมิชชั่นได้ถึงเวลา 23.59 น. ตามเวลาประเทศไทย
          </TermList>
          <TermList>
            เงินจะเข้า กระเป๋า(Wallet) ของสมาชิกทันทีหลังจากการปั่น Topspin
          </TermList>
          <TermList>
            ALLTOP ASIA จะคำนวนยอดเทิร์นโอเวอร์ในเเต่ละวัน
            ที่เเน่นอนเเล้วเท่านั้น โดยผลเสมอ, เดิมพัน 2 ฝั่ง, บิลรีเจ็ค,
            ยกเลิกเกมส์ จะไม่นับรวมในการคิดยอดเทิร์นโอเวอร์
          </TermList>
          <TermList>โปรโมชั่นนี้สามารถใช้ร่วมกับโปรโมชั่นอื่นๆได้</TermList>

          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ ALLTOP ASIA
            เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
            รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
          </TermList>
          <TermList>
            กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ ALLTOP
            ASIA ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
          </TermList>
          <TermList>
            ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
            ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>
          <TermList>
            ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
            บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
            แอดเดรสเดียวเท่านั้น
          </TermList>
          <TermList>การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด</TermList>
        </TermDesc>
      </Term>

      {/* <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' ? 'หมายเหตุ: ' : 'Note: '
            }
          </IntlContextConsumer>
          สามารถรับได้ไม่จำกัดโบนัส และไม่จำกัดครั้ง
        </NoteContent>
      </Note> */}

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionDetail16;
