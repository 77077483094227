import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--008.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              '918Kiss, Mega888, XE88, Pussy888 welcome bonus 100% รับสูงสุด 500 บาท'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              '918Kiss, Mega888, XE88, Pussy888 welcome bonus 100% รับสูงสุด 500 บาท'
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'ชอบเล่น SLOT&GAME เราจัดให้ รับโบนัส 100% สูงสุด 500 บาท'
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'ชอบเล่น SLOT&GAME เราจัดให้ รับโบนัส 100% สูงสุด 500 บาท'
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      {/* <Provider>
        <ProviderTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "เว็บไซต์ที่เข้าร่วมโปรโมชั่น"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "Providers Participate in Promotions"
            }
          </IntlContextConsumer>
        </ProviderTitle>

        <ProviderList>
          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-slot--918kiss.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>918 Kiss</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-slot--mega888.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>Mega 888</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-slot--pussy888.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>Pussy 888</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-slot--xe88.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>XE 88</ProviderListAppTitle>
          </ProviderListApp>
        </ProviderList>
      </Provider>

      <Claim>
        <ClaimButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && (
                <ButtonTheme
                  blank="https://app.alltopasia2.com/login"
                  icon="money"
                  title="รับโบนัส"
                  type="gold"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && (
                <ButtonTheme
                  blank="https://app.alltopasia2.com/login"
                  icon="money"
                  title="Claim Bonus"
                  type="gold"
                />
              )
            }
          </IntlContextConsumer>
        </ClaimButton>
      </Claim>
 */}

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'ฝากขั้นต่ำ 300 บาท รับเลยโบนัส 100% สูงสุด 500 บาท สำหรับสมาชิกใหม่เท่านั้น'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'ฝากขั้นต่ำ 300 บาท รับเลยโบนัส 100% สูงสุด 500 บาท สำหรับสมาชิกใหม่เท่านั้น'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                '918Kiss, Mega888, XE88, Pussy888 welcome bonus 100% รับสูงสุด'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                '918Kiss, Mega888, XE88, Pussy888 welcome bonus 100% รับสูงสุด'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นกำไร'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                'วิธีคำนวณยอดกำไร: ยอดฝาก + โบนัสที่ได้รับ x (3.5) = ยอดกำไรที่ต้องทำจึงจะถอนได้ (รวมทุน)'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                'วิธีคำนวณยอดกำไร: ยอดฝาก + โบนัสที่ได้รับ x (3.5) = ยอดกำไรที่ต้องทำจึงจะถอนได้ (รวมทุน)'
              }
            </IntlContextConsumer>
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <>
                  <TermList>โปรโมชั่นนี้สำหรับสมาชิกใหม่เท่านั้น</TermList>
                  <TermList>
                    โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
                  </TermList>
                  <TermList>
                    สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง
                  </TermList>
                  <TermList>
                    ต้องทำยอดเทิร์นกำไร 3,5 เท่ารวมทุน จึงจะสามารถถอนได้
                  </TermList>
                  <TermList>
                    หากสมาชิกมีการเดิมพันคาสิโนสด
                    เงื่อนไขจะเปลี่ยนเป็นโปรโมชั่นคาสิโนสดทันที
                    ต้องทำเทิร์นโอเวอร์ 40 เท่า จึงจะทำการถอนได้
                  </TermList>
                  <TermList>
                    ผลเสมอ, เดิมพัน 2 ฝั่ง, บิลรีเจ็ค, ยกเลิกเกมส์
                    จะไม่นับรวมในการคิดยอดเทิร์นโอเวอร์
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ากับฐานข้อมูลของ
                    ALLTOP เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
                    รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
                  </TermList>
                  <TermList>
                    หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นกำไร
                    สามารถเลือกไม่รับโปรโมชั่นได้
                  </TermList>
                  <TermList>
                    หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ
                    ฝากซ้า,ถอน,ยกเลิก,โอนย้าย
                    ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
                  </TermList>
                  <TermList>
                    กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นกำไรผิดปกติ
                    ALLTOP
                    ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
                  </TermList>
                  <TermList>
                    ALLTOP ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
                    ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </TermList>
                  <TermList>
                    ทุกโปรโมชั่นของ ALLTOP จำกัดสำหรับ 1
                    บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
                    แอดเดรสเดียวเท่านั้น
                  </TermList>
                  <TermList>
                    หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
                    สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
                    จึงจะสามารถทำรายการถอนได้
                  </TermList>
                  <TermList>การตัดสินของ ALLTOP ถือเป็นที่สิ้นสุด </TermList>
                  <TermList>
                    ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 10/10/2019 เป็นต้นไป
                  </TermList>
                </>
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <>
                  <TermList>โปรโมชั่นนี้สำหรับสมาชิกใหม่เท่านั้น</TermList>
                  <TermList>
                    โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
                  </TermList>
                  <TermList>
                    สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง
                  </TermList>
                  <TermList>
                    ต้องทำยอดเทิร์นกำไร 3,5 เท่ารวมทุน จึงจะสามารถถอนได้
                  </TermList>
                  <TermList>
                    หากสมาชิกมีการเดิมพันคาสิโนสด
                    เงื่อนไขจะเปลี่ยนเป็นโปรโมชั่นคาสิโนสดทันที
                    ต้องทำเทิร์นโอเวอร์ 40 เท่า จึงจะทำการถอนได้
                  </TermList>
                  <TermList>
                    ผลเสมอ, เดิมพัน 2 ฝั่ง, บิลรีเจ็ค, ยกเลิกเกมส์
                    จะไม่นับรวมในการคิดยอดเทิร์นโอเวอร์
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ากับฐานข้อมูลของ
                    ALLTOP เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
                    รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
                  </TermList>
                  <TermList>
                    หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นกำไร
                    สามารถเลือกไม่รับโปรโมชั่นได้
                  </TermList>
                  <TermList>
                    หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ
                    ฝากซ้า,ถอน,ยกเลิก,โอนย้าย
                    ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
                  </TermList>
                  <TermList>
                    กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นกำไรผิดปกติ
                    ALLTOP
                    ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
                  </TermList>
                  <TermList>
                    ALLTOP ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
                    ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </TermList>
                  <TermList>
                    ทุกโปรโมชั่นของ ALLTOP จำกัดสำหรับ 1
                    บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
                    แอดเดรสเดียวเท่านั้น
                  </TermList>
                  <TermList>
                    หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
                    สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
                    จึงจะสามารถทำรายการถอนได้
                  </TermList>
                  <TermList>การตัดสินของ ALLTOP ถือเป็นที่สิ้นสุด </TermList>
                  <TermList>
                    ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 10/10/2019 เป็นต้นไป
                  </TermList>
                </>
              )
            }
          </IntlContextConsumer>
        </TermDesc>
      </Term>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'สมาชิกที่รับโปรโมชั่นนี้ไม่สามารถเดิมพันคาสิโนสดได้ หากมีการเดิมพันในคาสิโนสด ALLTOP จะไม่ให้ทาการถอนทุกกรณี'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'สมาชิกที่รับโปรโมชั่นนี้ไม่สามารถเดิมพันคาสิโนสดได้ หากมีการเดิมพันในคาสิโนสด ALLTOP จะไม่ให้ทาการถอนทุกกรณี'
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
