import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--012.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          สุขสันต์วันเกิดสมาชิก ALLTOP ASIA แจกโบนัส 100 %
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;เราขอร่วมแสดงความยินดีโดยการมอบของขวัญวันเกิดแด่สมาชิก ALLTOP
          ASIA&quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            สมาชิกจะได้รับโบนัสเพิ่มอีก 100 % จากยอดฝากเเรกของวัน
            (ภายในวันเกิดเท่านั้น)
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            สมาชิกต้องมีรายการฝากอย่างน้อย 3 ครั้ง ภายในรอบเวลา 30
            วันจึงจะมีสิทธิ์รับโปรโมชั่นวันเกิด
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'เว็บที่ร่วมโปรโมชั่นนี้'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Website'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' && 'ทุกเว็บในเครือ'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' && 'ทุกเว็บในเครือ'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'วิธีคำนวณยอดเทิร์นโอเวอร์'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'How to calculate turnover'
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            โบนัสที่ได้รับ x 10 = ยอดเทิร์นโอเวอร์ที่ต้องทำจึงจะถอนได้
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>
            โบนัสนี้ขอมอบให้แด่สมาชิกที่เกิดในวันนั้นๆ เท่านั้น
          </TermList>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่เคยทำการฝากแล้วอย่างน้อย 3
            ครั้ง จึงจะมีคุณสมบัติรับโบนัสในโปรโมชั่นนี้
          </TermList>
          <TermList>
            ยอดเงินในกระเป๋าหลักของสมาชิกต้องเป็น 0 เท่านั้น
            ถึงเติมโบนัสวันเกิดให้กับสมาชิก
          </TermList>
          <TermList>
            โปรโมชั่นนี้จะต้องทำเทิร์นโอเวอร์ 10 เท่า จึงจะทำการถอนได้
          </TermList>
          <TermList>
            ในส่วนของ 918Kiss, Pussy888 การเดิมพันสล็อตต้องทำยอดกำไร 5 เท่า
            การเดิมพันเกมส์และคาสิโนต้องทำยอดกำไร 10 เท่า
            และไม่สามารถเดิมพันคาสิโนสดได้
            หากมีการเดิมพันคาสิโนสดจะทำการยึดโบนัสคืนทันที
          </TermList>
          <TermList>
            สมาชิกต้องมีรายการฝากอย่างน้อย 3 ครั้ง ภายในรอบเวลา 30
            วันจึงจะมีสิทธิ์รับโปรโมชั่นวันเกิด
          </TermList>
          <TermList>
            สมาชิกต้องยื่นหลักฐานตัวจริงเท่านั้น ห้ามเป็นสำเนาโดยเด็ดขาด
            หากยื่นเป็นบัตรประจำตัวประชาชน, ใบขับขี่,
            พาสปอร์ต จะต้องเห็นลายน้ำอย่างชัดเจน
          </TermList>
          <TermList>โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้</TermList>
          <TermList>
            โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ ALLTOP ASIA
            เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี รางวัล
            หรือโบนัสทั้งหมดจะถูกริบคืนทันที
          </TermList>
          <TermList>
            หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
            ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
          </TermList>
          <TermList>
            กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ ALLTOP
            ASIA ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
          </TermList>
          <TermList>
            ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
            ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
          </TermList>
          <TermList>
            ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
            บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
            แอดเดรสเดียวเท่านั้น
          </TermList>
          <TermList>
            หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
            สมาชิกต้องมีการเดิมพันหรือยอดเทริน 1 เท่าของยอดฝาก
            จึงจะสามารถทำรายการถอนได้
          </TermList>
          <TermList>การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด</TermList>
          <TermList>
            หากต้องการรับโปรโมชั่นแนะนำเพื่อนครั้งต่อไป
            ต้องทำตามเงื่อนไขใหม่อีกครั้ง
          </TermList>
          <TermList>
            สมาชิกจะต้องทำการแจ้งกับทางฝ่ายบริการลูกค้า ALLTOP ASIA ทาง Line@
            ภายใน 24 ชั่วโมง
          </TermList>
          <TermList>
            ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 01/01/2021 เป็นต้นไป
          </TermList>
        </TermDesc>
      </Term>

      <Note>
        <NoteContent>
          สมาชิกต้องมีรายการฝากอย่างน้อย 3 ครั้ง ภายในรอบเวลา 30
          วันจึงจะมีสิทธิ์รับโปรโมชั่นวันเกิด
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
