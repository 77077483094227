import React from 'react';
import { ImageResponsive } from 'utils';
import { Link, IntlContextConsumer } from 'gatsby-plugin-intl';
import State from './styled';

const Hilight = () => (
  <State
    arrows={false}
    arrowsBlock={false}
    autoplaySpeed={5000}
    dots={false}
    duration={750}
    autoplay
  >
    {/*
    <div>
      <Link to="/allbet/drawredpacket9000000000">
        <ImageResponsive
          source="H5MOBILE_Banner_th.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>
    */}

    {/* <div>
      <ImageResponsive
        source="1699507698977.png"
        alt="ALLTOP Promotions"
        fadeIn
        placeholder
      />
    </div> */}
    {/*
    <div>
      <ImageResponsive
        source="1699507704598"
        alt="ALLTOP Promotions"
        fadeIn
        placeholder
      />
    </div> */}

    {/*
    <div>
      <ImageResponsive
        source="pragmatic-dailywin-th.png"
        alt="ALLTOP Promotions"
        fadeIn
        placeholder
      />
    </div>
    */}

    {/*
      <div>
        <ImageResponsive
          source="spadegaming-12-6.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </div>
    */}

    <div>
      <ImageResponsive
        source="1704893097345.jpg"
        alt="ALLTOP Promotions"
        fadeIn
        placeholder
      />
    </div>

    <div>
      <ImageResponsive
        source="pragmatic-15-12.jpg"
        alt="ALLTOP Promotions"
        fadeIn
        placeholder
      />
    </div>

    <div>
      <a
        href="https://allmoviefun.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResponsive
          source="banner-alltopasia-allmoviefun.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </a>
    </div>

    <IntlContextConsumer>
      {({ language: currentLanguage }) =>
        currentLanguage === 'en' ? (
          <div>
            <ImageResponsive
              source="joker-3888888.jpg"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </div>
        ) : (
          <div>
            <ImageResponsive
              source="joker-new.png"
              alt="ALLTOP Promotions"
              fadeIn
              placeholder
            />
          </div>
        )
      }
    </IntlContextConsumer>

    <div>
      <Link to="/promotions/allaffiliate">
        <ImageResponsive
          source="hilight-banner--2.jpg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>

    <div>
      <Link to="/promotions/cashback">
        <ImageResponsive
          source="banner-alltopasia-promotion-cover--14%-per-week.jpeg"
          alt="ALLTOP Promotions"
          fadeIn
          placeholder
        />
      </Link>
    </div>
  </State>
);

export default Hilight;
