import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Term,
  TermDesc,
  TermList,
  TermTitle,
} from './styled';

const PromotionCashback = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="banner-alltopasia-promotion-cover--14%-per-week.jpeg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'คืนยอดเสีย 14% รายสัปดาห์ไม่จำกัด! ไม่ต้องทำเทิร์น!'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'คืนยอดเสีย 14% รายสัปดาห์ไม่จำกัด! ไม่ต้องทำเทิร์น!'
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'คืนยอดเล่น 1% รายวันไม่ต้องทำเทิร์น!'
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'คืนยอดเล่น 1% รายวันไม่ต้องทำเทิร์น!'
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' && 'สามารถรับยอดเงินคืนได้ที่ TOPSPIN'
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' && 'สามารถรับยอดเงินคืนได้ที่ TOPSPIN'
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ: '
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note: '
            }
          </IntlContextConsumer>
          สมาชิกสามารถสอบถามรายละเอียดเพิ่มเติมที่ฝ่ายบริการลูกค้า Line:
          @Alltopfun
        </NoteContent>
      </Note>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ข้อตกลงและเงื่อนไข'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Terms and conditions'
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <TermList>
            สมาชิกต้องมาขอเเจ้งรับว่าจะเลือกคืนยอดเล่นหรือยอดเสียให้ฝ่ายบริการลูกค้า
            Line: @alltopfun
          </TermList>

          <TermList>
            โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
          </TermList>
        </TermDesc>
      </Term>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionCashback;
