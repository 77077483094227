import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  TermDesc,
  TermList,
} from './styled';

const PromotionsDetail = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="H5MOBILE_Banner_th.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'จับฉลากโบนัสอั่งเปา สูงสุดถึง 9,000,0000 บาท'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'จับฉลากโบนัสอั่งเปา สูงสุดถึง 9,000,0000 บาท'
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'ยิ่งเล่นมาก ยิ่งชนะมาก ไม่ที่สินสุด'
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'ยิ่งเล่นมาก ยิ่งชนะมาก ไม่ที่สินสุด'
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'คำแนะนำ'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                `ผู้เล่นมีโอกาศที่จะได้รับเงินรางวัลสูงสุด 9 ล้านบาทจากกิจกรรมอั่งเปา โดยทุกท่านสามารถร่วมสนุกได้ง่ายๆ ไม่มีเกณฑ์การเดิมพันขั้นต่ำ กิจกรรมจะเริ่มตั้งแต่วันที่ 4 เมษายน 2022 ยิ่งเดิมพันมากยิ่งมีโอกาศได้จับอั่งเปามาก`
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                `ผู้เล่นมีโอกาศที่จะได้รับเงินรางวัลสูงสุด 9 ล้านบาทจากกิจกรรมอั่งเปา โดยทุกท่านสามารถร่วมสนุกได้ง่ายๆ ไม่มีเกณฑ์การเดิมพันขั้นต่ำ กิจกรรมจะเริ่มตั้งแต่วันที่ 4 เมษายน 2022 ยิ่งเดิมพันมากยิ่งมีโอกาศได้จับอั่งเปามาก`
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'th' &&
                `ระยะเวลากิจกรรม

                4 เมษายน 2022 ถึง 6 มิถุนายน 2022`
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === 'en' &&
                `ระยะเวลากิจกรรม

                4 เมษายน 2022 ถึง 6 มิถุนายน 2022`
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'กฏกติกา'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note'
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <TermDesc>
        <TermList>
          หากตั๋วเดิมพันของท่านลงท้ายด้วย เลข “9” 3 ตัว
          ท่านจะได้รับอั่งเปายิ่งตั๋วเดิมพันมีเลข9มากเท่าไหร่
          รางวัลจะยิ่งมากขึ้น
        </TermList>

        <TermList>ยิ่งวางเดิมพันมาก ยิ่งมีโอกาศได้รับอั่งเปามาก</TermList>

        <TermList>ท่านจะได้รับเงินรางวัลทันทีที่เปิดอั่งเปา</TermList>

        <TermList>ท่านสามารถได้รับอั่งเปาแบบไม่จำกัดจำนวน</TermList>

        <TermList>
          ในกรณีที่มีข้อพิพาท Allbet Group ขอสงวนสิทธิ์ในการตัดสินขั้นสุดท้าย
        </TermList>
      </TermDesc>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionsDetail;
