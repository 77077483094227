import React from 'react';
import { ButtonTheme } from 'components';
import { ImageResponsive } from 'utils';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailDescContentLink,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
} from './styled';

const PromotionAllreward20Percent = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="allreward20.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>ALLREWARD จัดให้ ลดสูงสุด 20%</HeadiingTitle>

        <HeadiingSubtitle>
          &quot;สิทธิ์ประโยชนพ์ ิเศษเฉพาะลกู คา้ ALLTOP สะสมคะแนนกับ
          ALLREWARDS&quot;
        </HeadiingSubtitle>
      </Headiing>

      {/*
      <Claim>
        <ClaimButton>
          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'th' && <ButtonTheme blank="https://app.alltopasia2.com/login" icon="money" title="รับโบนัส" type="gold" />)
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {
              ({ language: currentLanguage }) => (currentLanguage === 'en' && <ButtonTheme blank="https://app.alltopasia2.com/login" icon="money" title="Claim Bonus" type="gold" />)
            }
          </IntlContextConsumer>
        </ClaimButton>
      </Claim>
      */}

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'รายละเอียด'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Details'
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            Allreward ลด 20% ตั้งแต่วันที่ 20 มิ.ย. – 31 ก.ค. 64
            แลกของรางวัลได้ที่{' '}
            <DetailDescContentLink
              href="https://www.alltoppoint.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ALLTOP REWARD
            </DetailDescContentLink>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && 'หมายเหตุ: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && 'Note: '
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' &&
              'สะสมแลกของรางวัลมากมาย เป็นสิทธิพิเศษและคืนกำไรแด่ลูกค้า ALLTOP ทุกท่าน'
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' &&
              'สะสมแลกของรางวัลมากมาย เป็นสิทธิพิเศษและคืนกำไรแด่ลูกค้า ALLTOP ทุกท่าน'
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'th' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === 'en' && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
);

export default PromotionAllreward20Percent;
