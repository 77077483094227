import React from "react"
import { ButtonTheme } from "components"
import { ImageResponsive } from "utils"
import { IntlContextConsumer } from "gatsby-plugin-intl"
import {
  State,
  Detail,
  DetailDesc,
  DetailDescContent,
  DetailTitle,
  Headiing,
  HeadiingSubtitle,
  HeadiingTitle,
  Hilight,
  Howto,
  HowtoDesc,
  HowtoDescContent,
  HowtoTitle,
  Main,
  More,
  MoreButton,
  Note,
  NoteContent,
  Provider,
  ProviderTitle,
  ProviderList,
  ProviderListApp,
  ProviderListAppCover,
  ProviderListAppTitle,
  Term,
  TermDesc,
  TermList,
  TermTitle,
  Claim,
  ClaimButton,
} from "./styled"

const PromotionDetail14 = () => (
  <State>
    <Hilight>
      <ImageResponsive
        source="promotion-cover--014.jpg"
        alt="ALLTOP ASIA Promotions"
        fadeIn
        placeholder
      />
    </Hilight>

    <Main>
      <Headiing>
        <HeadiingTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "Casino Daily Bonus 10%"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "Casino Daily Bonus 10%"
            }
          </IntlContextConsumer>
        </HeadiingTitle>

        <HeadiingSubtitle>
          &quot;
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" &&
              "Casino Daily Bonus 10% สูงสุด 500 บาท!"
            }
          </IntlContextConsumer>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" &&
              "Casino Daily Bonus 10% สูงสุด 500 บาท!"
            }
          </IntlContextConsumer>
          &quot;
        </HeadiingSubtitle>
      </Headiing>

      {/*
      <Provider>
        <ProviderTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && 'เว็บไซต์ที่เข้าร่วมโปรโมชั่น'}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && 'Providers Participate in Promotions'}
          </IntlContextConsumer>
        </ProviderTitle>

        <ProviderList>
          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-casino--evolutiongaming.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>Evolution Gaming</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-casino--gclubcasino.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>G Club Casino</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-casino--sagaming.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>SA Gaming</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-casino--sexybarcarat.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>Sexy Barcarat</ProviderListAppTitle>
          </ProviderListApp>

          <ProviderListApp>
            <ProviderListAppCover>
              <ImageResponsive
                source="app-casino--wmcasino.png"
                alt="ALLTOP ASIA Casino Online"
                fadeIn
              />
            </ProviderListAppCover>

            <ProviderListAppTitle>WM Casino</ProviderListAppTitle>
          </ProviderListApp>
        </ProviderList>
      </Provider>

      <Claim>
        <ClaimButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'th' && (
              <ButtonTheme
                blank="https://app.alltopasia2.com/login"
                icon="money"
                title="รับโบนัส"
                type="gold"
              />
            )}
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) => currentLanguage === 'en' && (
              <ButtonTheme
                blank="https://app.alltopasia2.com/login"
                icon="money"
                title="Claim Bonus"
                type="gold"
              />
            )}
          </IntlContextConsumer>
        </ClaimButton>
      </Claim>
      */}

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "รายละเอียด"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "Details"
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === "th" &&
                "ฝากขั้นต่ำ 300 บาท รับโบนัสทันที 10% สูงสุด 500 บาท"
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === "en" &&
                "ฝากขั้นต่ำ 300 บาท รับโบนัสทันที 10% สูงสุด 500 บาท"
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Detail>
        <DetailTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "เว็บที่ร่วมโปรโมชั่นนี้"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "เว็บที่ร่วมโปรโมชั่นนี้"
            }
          </IntlContextConsumer>
        </DetailTitle>

        <DetailDesc>
          <DetailDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === "th" &&
                "SA Gaming, Sexy Baccarat, WM Casino, Evolution Gaming, G club"
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === "en" &&
                "SA Gaming, Sexy Baccarat, WM Casino, Evolution Gaming, G club"
              }
            </IntlContextConsumer>
          </DetailDescContent>
        </DetailDesc>
      </Detail>

      <Howto>
        <HowtoTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "วิธีคำนวณยอดเทิร์นโอเวอร์"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "How to calculate turnover"
            }
          </IntlContextConsumer>
        </HowtoTitle>

        <HowtoDesc>
          <HowtoDescContent>
            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === "th" &&
                "ยอดฝาก + โบนัสที่ได้รับ x (15) = ยอดเทิร์นโอเวอร์ที่ต้องทำจึงจะถอนได้"
              }
            </IntlContextConsumer>

            <IntlContextConsumer>
              {({ language: currentLanguage }) =>
                currentLanguage === "en" &&
                "ยอดฝาก + โบนัสที่ได้รับ x (15) = ยอดเทิร์นโอเวอร์ที่ต้องทำจึงจะถอนได้"
              }
            </IntlContextConsumer>
          </HowtoDescContent>
        </HowtoDesc>
      </Howto>

      <Term>
        <TermTitle>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "ข้อตกลงและเงื่อนไข"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "Terms and conditions"
            }
          </IntlContextConsumer>
        </TermTitle>

        <TermDesc>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && (
                <>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินเข้าเว็บ ALLTOP ASIA
                    แล้วอย่างน้อย 1 ครั้ง จึงจะมีคุณสมบัติรับโบนัสในโปรโมชั่นนี้
                  </TermList>

                  <TermList>
                    สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น
                  </TermList>

                  <TermList>
                    โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่ำ 300 บาท จึงจะได้รับโบนัส
                  </TermList>

                  <TermList>
                    สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง
                  </TermList>

                  <TermList>
                    โปรโมชั่นนี้ต้องทำยอดเทิร์นโอเวอร์ 15 เท่า จึงจะสามารถถอนได้
                  </TermList>

                  <TermList>
                    โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้
                  </TermList>

                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ำกับฐานข้อมูลของ
                    ALLTOP ASIA เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่งบัญชี
                    รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
                  </TermList>

                  <TermList>
                    หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์
                    สามารถเลือกไม่รับโปรโมชั่นได้
                  </TermList>

                  <TermList>
                    หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ
                    ฝากซ้ำ,ถอน,ยกเลิก,โอนย้าย
                    ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
                  </TermList>

                  <TermList>
                    กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ
                    ALLTOP ASIA
                    ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
                  </TermList>

                  <TermList>
                    ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
                    ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </TermList>

                  <TermList>
                    ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
                    บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
                    แอดเดรสเดียวเท่านั้น
                  </TermList>

                  <TermList>
                    หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
                    สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
                    จึงจะสามารถทำรายการถอนได้
                  </TermList>

                  <TermList>
                    การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด
                  </TermList>
                </>
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && (
                <>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีการฝากเงินเข้าเว็บ ALLTOP ASIA
                    แล้วอย่างน้อย 1 ครั้ง จึงจะมีคุณสมบัติรับโบนัสในโปรโมชั่นนี้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สมาชิกจะต้องฝากขั้นต่า 300 บาท จึงจะได้รับโบนัส
                  </TermList>
                  <TermList>
                    สมาชิกสามารถขอรับโบนัสผ่านระบบได้ด้วยตนเอง
                  </TermList>
                  <TermList>
                    ต้องทายอดเทิร์นโอเวอร์ 25 เท่ารวมทุน จึงจะสามารถถอนได้
                  </TermList>
                  <TermList>
                    ผลเสมอ, เดิมพัน 2 ฝั่ง, บิลรีเจ็ค, ยกเลิกเกมส์
                    จะไม่นับรวมในการคิดยอดเทิร์นโอเวอร์
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่นๆ ได้
                  </TermList>
                  <TermList>
                    โปรโมชั่นนี้สำหรับสมาชิกที่มีข้อมูลไม่ซ้ากับฐานข้อมูลของ
                    ALLTOP ASIA เท่านั้น หากพบว่ามีการสมัครมากกว่าหนึ่ง9บัญชี
                    รางวัลหรือโบนัสทั้งหมดจะถูกริบคืนทันที
                  </TermList>
                  <TermList>
                    หากสมาชิกไม่ต้องการติดเงื่อนไขเทิร์นโอเวอร์
                    สามารถเลือกไม่รับโปรโมชั่นได้
                  </TermList>
                  <TermList>
                    หากรับโปรโมชั่นแล้วสมาชิกจะไม่สามารถ
                    ฝากซ้า,ถอน,ยกเลิก,โอนย้าย
                    ใดๆได้จนกว่าจะทำยอดเทิร์นโอเวอร์ครบกำหนดตามเงื่อนไขหรือจนกว่าเครดิตจะหมด
                  </TermList>
                  <TermList>
                    กรณีตรวจสอบพบการทุจริต หรือ การได้มาซึ่งเทิร์นโอเวอร์ผิดปกติ
                    ALLTOP ASIA
                    ขอสงวนสิทธิ์ในการยกเลิกโปรโมชั่นและอายัดยอดเงินในบัญชีทันที
                  </TermList>
                  <TermList>
                    ALLTOP ASIA ขอสงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลง หรือ
                    ยกเลิกโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </TermList>
                  <TermList>
                    ทุกโปรโมชั่นของ ALLTOP ASIA จำกัดสำหรับ 1
                    บุคคล,ชื่อ-สกุล,ที่อยู่,อีเมล์,เบอร์โทรศัพท์,บัญชีธนาคาร,IP
                    แอดเดรสเดียวเท่านั้น
                  </TermList>
                  <TermList>
                    หากสมาชิกทำรายการฝากเงินหรือโอนย้ายเข้าเว็บเพื่อเดิมพันโดยไม่รับโปรโมชั่น
                    สมาชิกต้องมียอดเทิร์นโอเวอร์ 1 เท่าของยอดฝาก
                    จึงจะสามารถทำรายการถอนได้
                  </TermList>
                  <TermList>
                    การตัดสินของ ALLTOP ASIA ถือเป็นที่สิ้นสุด
                  </TermList>
                  <TermList>
                    ข้อตกลงและเงื่อนไขมีผลบังคับใช้ตั้งแต่ 01/03/2020 เป็นต้นไป
                  </TermList>
                </>
              )
            }
          </IntlContextConsumer>
        </TermDesc>
      </Term>

      <Note>
        <NoteContent>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && "หมายเหตุ: "
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && "Note: "
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" &&
              "สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น"
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" &&
              "สามารถรับได้ 1 ครั้งต่อ 1 วัน ต่อ 1 ยูสเซอร์เท่านั้น"
            }
          </IntlContextConsumer>
        </NoteContent>
      </Note>

      <More>
        <MoreButton>
          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "th" && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="โปรโมชั่นทั้งหมด"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>

          <IntlContextConsumer>
            {({ language: currentLanguage }) =>
              currentLanguage === "en" && (
                <ButtonTheme
                  to="/promotions"
                  icon="promotion"
                  title="More Promotions"
                  type="silver"
                />
              )
            }
          </IntlContextConsumer>
        </MoreButton>
      </More>
    </Main>
  </State>
)

export default PromotionDetail14
