import React from 'react';
import { ImageResponsive } from 'utils';
import useWindowDimensions from 'hooks';
import { useIntl } from 'gatsby-plugin-intl';
import winners from './data';

import {
  State,
  Card,
  CardCover,
  CardCoverBlank,
  CardStat,
  CardStatButton,
  CardStatDesc,
  CardStatName,
  CardStatNumber,
  CardStatProvider,
  CardStatRequest,
  CardStatReward,
  CardStatTitle,
  Carousel,
  Jackpot,
} from './styled';

const Winners = () => {
  const intl = useIntl();
  const { width } = useWindowDimensions();

  return (
    <State>
      <Jackpot>
        {intl.formatMessage({ id: 'screen.winners.title' })}
      </Jackpot>

      {width > 1400 && (
        <Carousel
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={6}
          swipe={false}
          autoplay
        >
          {
            Object.keys(winners).map((key) => (
              <div key={key}>
                <Card>
                  <CardCover>
                    <CardCoverBlank href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                      <ImageResponsive
                        source={winners[key].thumbnail}
                        alt={winners[key].provider}
                      />
                    </CardCoverBlank>
                  </CardCover>

                  <CardStat>
                    <CardStatDesc>
                      <CardStatTitle>
                        <CardStatName>
                          {intl.formatMessage({ id: 'screen.winners.winner' })}
                        </CardStatName>
                        <CardStatNumber>{winners[key].user}</CardStatNumber>
                      </CardStatTitle>

                      <CardStatReward>
                        {winners[key].reward}
                      </CardStatReward>

                      <CardStatProvider>
                        {intl.formatMessage({ id: 'screen.winners.from' })}
                        {' '}
                        {winners[key].provider}
                      </CardStatProvider>
                    </CardStatDesc>

                    <CardStatRequest>
                      <CardStatButton href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                        {intl.formatMessage({ id: 'screen.winners.play' })}
                      </CardStatButton>
                    </CardStatRequest>
                  </CardStat>
                </Card>
              </div>
            ))
          }
        </Carousel>
      )}

      {(width > 992 && width < 1399) && (
        <Carousel
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={4}
          swipe={false}
          autoplay
        >
          {
            Object.keys(winners).map((key) => (
              <div key={key}>
                <Card>
                  <CardCover>
                    <CardCoverBlank href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                      <ImageResponsive
                        source={winners[key].thumbnail}
                        alt={winners[key].provider}
                      />
                    </CardCoverBlank>
                  </CardCover>

                  <CardStat>
                    <CardStatDesc>
                      <CardStatTitle>
                        <CardStatName>{intl.formatMessage({ id: 'screen.winners.winner' })}</CardStatName>
                        <CardStatNumber>{winners[key].user}</CardStatNumber>
                      </CardStatTitle>

                      <CardStatReward>
                        {winners[key].reward}
                      </CardStatReward>

                      <CardStatProvider>
                        {intl.formatMessage({ id: 'screen.winners.from' })}
                        {' '}
                        {winners[key].provider}
                      </CardStatProvider>
                    </CardStatDesc>

                    <CardStatRequest>
                      <CardStatButton href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                        {intl.formatMessage({ id: 'screen.winners.play' })}
                      </CardStatButton>
                    </CardStatRequest>
                  </CardStat>
                </Card>
              </div>
            ))
          }
        </Carousel>
      )}

      {(width > 768 && width < 991) && (
        <Carousel
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={3}
          swipe={false}
          autoplay
        >
          {
            Object.keys(winners).map((key) => (
              <div key={key}>
                <Card>
                  <CardCover>
                    <CardCoverBlank href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                      <ImageResponsive
                        source={winners[key].thumbnail}
                        alt={winners[key].provider}
                      />
                    </CardCoverBlank>
                  </CardCover>

                  <CardStat>
                    <CardStatDesc>
                      <CardStatTitle>
                        <CardStatName>{intl.formatMessage({ id: 'screen.winners.winner' })}</CardStatName>
                        <CardStatNumber>{winners[key].user}</CardStatNumber>
                      </CardStatTitle>

                      <CardStatReward>
                        {winners[key].reward}
                      </CardStatReward>

                      <CardStatProvider>
                        {intl.formatMessage({ id: 'screen.winners.from' })}
                        {' '}
                        {winners[key].provider}
                      </CardStatProvider>
                    </CardStatDesc>

                    <CardStatRequest>
                      <CardStatButton href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                        {intl.formatMessage({ id: 'screen.winners.play' })}
                      </CardStatButton>
                    </CardStatRequest>
                  </CardStat>
                </Card>
              </div>
            ))
          }
        </Carousel>
      )}

      {(width > 670 && width < 767) && (
        <Carousel
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={3}
          swipe={false}
          autoplay
        >
          {
            Object.keys(winners).map((key) => (
              <div key={key}>
                <Card>
                  <CardCover>
                    <CardCoverBlank href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                      <ImageResponsive
                        source={winners[key].thumbnail}
                        alt={winners[key].provider}
                      />
                    </CardCoverBlank>
                  </CardCover>

                  <CardStat>
                    <CardStatDesc>
                      <CardStatTitle>
                        <CardStatName>{intl.formatMessage({ id: 'screen.winners.winner' })}</CardStatName>
                        <CardStatNumber>{winners[key].user}</CardStatNumber>
                      </CardStatTitle>

                      <CardStatReward>
                        {winners[key].reward}
                      </CardStatReward>

                      <CardStatProvider>
                        {intl.formatMessage({ id: 'screen.winners.from' })}
                        {' '}
                        {winners[key].provider}
                      </CardStatProvider>
                    </CardStatDesc>

                    <CardStatRequest>
                      <CardStatButton href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                        {intl.formatMessage({ id: 'screen.winners.play' })}
                      </CardStatButton>
                    </CardStatRequest>
                  </CardStat>
                </Card>
              </div>
            ))
          }
        </Carousel>
      )}

      {(width < 669) && (
        <Carousel
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={2}
          swipe={false}
          autoplay
        >
          {
            Object.keys(winners).map((key) => (
              <div key={key}>
                <Card>
                  <CardCover>
                    <CardCoverBlank href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                      <ImageResponsive
                        source={winners[key].thumbnail}
                        alt={winners[key].provider}
                      />
                    </CardCoverBlank>
                  </CardCover>

                  <CardStat>
                    <CardStatDesc>
                      <CardStatTitle>
                        <CardStatName>{intl.formatMessage({ id: 'screen.winners.winner' })}</CardStatName>
                        <CardStatNumber>{winners[key].user}</CardStatNumber>
                      </CardStatTitle>

                      <CardStatReward>
                        {winners[key].reward}
                      </CardStatReward>

                      <CardStatProvider>
                        {intl.formatMessage({ id: 'screen.winners.from' })}
                        {' '}
                        {winners[key].provider}
                      </CardStatProvider>
                    </CardStatDesc>

                    <CardStatRequest>
                      <CardStatButton href="https://app.alltopasia2.com/login/" target="_blank" rel="noopener noreferrer">
                        {intl.formatMessage({ id: 'screen.winners.play' })}
                      </CardStatButton>
                    </CardStatRequest>
                  </CardStat>
                </Card>
              </div>
            ))
          }
        </Carousel>
      )}
    </State>
  );
};

export default Winners;
