import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { ImageResponsive } from 'utils';
import {
  State,
  Article,
  ArticleCover,
  ArticleTitle,
  ArticleDesc,
  Providers,
  ProviderApp,
  ProviderAppCover,
  ProviderAppTitle,
} from './styled';

const Applications = (props) => {
  const { type } = props;
  const intl = useIntl();

  const providersCasinos = {
    id_001: {
      title: 'Evolution Gaming',
      thumbnail: 'app-casino--evolutiongaming.png',
    },
    id_002: {
      title: 'G Club Casino',
      thumbnail: 'app-casino--gclubcasino.png',
    },
    id_003: {
      title: 'SA Gaming',
      thumbnail: 'app-casino--sagaming.png',
    },
    id_004: {
      title: 'Sexy Barcarat',
      thumbnail: 'app-casino--sexybarcarat.png',
    },
    id_005: {
      title: 'WM Casino',
      thumbnail: 'app-casino--wmcasino.png',
    },
  };

  const providersEsports = {
    id_001: {
      title: 'EZ Game',
      thumbnail: 'winner-provider--ezgame.png',
    },
  };

  const providersSports = {
    id_001: {
      title: 'Nova 88',
      thumbnail: 'app-sport--nova88.png',
    },
    id_002: {
      title: 'SBOBET',
      thumbnail: 'app-sport--sbobet.png',
    },
    id_003: {
      title: 'TS 911',
      thumbnail: 'app-sport--ts911.png',
    },
    id_004: {
      title: 'UFA Bet',
      thumbnail: 'app-sport--ufabet.png',
    },
    id_005: {
      title: 'M8BET',
      thumbnail: 'provider-sport--m8bet.png',
    },
  };

  const providersSlots = {
    id_009: {
      title: 'Pragmatic Play',
      thumbnail: 'provider-casino--pramaticplay.png',
    },
    id_001: {
      title: '918 Kiss',
      thumbnail: 'app-slot--918kiss.png',
    },
    id_002: {
      title: 'Joker Gaming',
      thumbnail: 'app-slot--joker.png',
    },
    id_003: {
      title: 'Mega 888',
      thumbnail: 'app-slot--mega888.png',
    },
    id_004: {
      title: 'Pussy 888',
      thumbnail: 'app-slot--pussy888.png',
    },
    id_005: {
      title: 'XE 88',
      thumbnail: 'app-slot--xe88.png',
    },
    id_006: {
      title: 'Slot Citi',
      thumbnail: 'app-slot--slotciti.png',
    },
    id_009po: {
      title: 'Playngo',
      thumbnail: 'app-slot--playngo.png',
    },
    id_009io: {
      title: 'Sample Play',
      thumbnail: 'app-slot--sampleplay.png',
    },
    id_009oo: {
      title: 'Slot XO',
      thumbnail: 'app-slot--slotxo.png',
    },

    id_007: {
      title: '3WIN8',
      thumbnail: 'provider-casino--3win8.png',
    },
    id_008: {
      title: 'Gaming Soft',
      thumbnail: 'provider-casino--gamingsoft.png',
    },

    id_010: {
      title: 'CQ9 Gaming',
      thumbnail: 'cq9-gaming_480.png',
    },
    id_011: {
      title: 'Habannero',
      thumbnail: 'harbanero.png',
    },
    id_012: {
      title: 'Micro Gaming',
      thumbnail: 'microgaming_480.png',
    },
    id_013: {
      title: 'PG Soft',
      thumbnail: 'pgpocketsoft_480.png',
    },
    id_014: {
      title: 'Spade Gaming',
      thumbnail: 'spadegaming.png',
    },
    id_01412: {
      title: 'King Maker',
      thumbnail: 'kingmaker.png',
    },
    id_084521SSS: {
      title: 'Funky Games',
      thumbnail: 'app-slot-funkygames.jpg',
    },
    fdfd1236fd: {
      title: 'AMB Poker',
      thumbnail: 'amb-poker.png',
    },

    ddkd456fkf: {
      title: 'Ameba',
      thumbnail: 'ameba.png',
    },

    rt_ddkd456fkf: {
      title: 'Big Gaming',
      thumbnail: 'biggaming.png',
    },
  };

  const providersCasinoList = Object.keys(providersCasinos).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia2.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderAppCover>
        <ImageResponsive
          source={providersCasinos[key].thumbnail}
          alt="ALLTOP ASIA Casino Online"
          fadeIn
        />
      </ProviderAppCover>

      <ProviderAppTitle>{providersCasinos[key].title}</ProviderAppTitle>
    </ProviderApp>
  ));

  const providersEsportsList = Object.keys(providersEsports).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia2.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderAppCover>
        <ImageResponsive
          source={providersEsports[key].thumbnail}
          alt="ALLTOP ASIA E-sports Betting Online"
          fadeIn
        />
      </ProviderAppCover>

      <ProviderAppTitle>{providersEsports[key].title}</ProviderAppTitle>
    </ProviderApp>
  ));

  const providersSportsList = Object.keys(providersSports).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia2.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderAppCover>
        <ImageResponsive
          source={providersSports[key].thumbnail}
          alt="ALLTOP ASIA Sports Betting Online"
          fadeIn
        />
      </ProviderAppCover>

      <ProviderAppTitle>{providersSports[key].title}</ProviderAppTitle>
    </ProviderApp>
  ));

  const providersSlotsList = Object.keys(providersSlots).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia2.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderAppCover>
        <ImageResponsive
          source={providersSlots[key].thumbnail}
          alt="ALLTOP ASIA Slots Online"
          fadeIn
        />
      </ProviderAppCover>

      <ProviderAppTitle>{providersSlots[key].title}</ProviderAppTitle>
    </ProviderApp>
  ));

  return (
    <State>
      <Article>
        <ArticleTitle>
          {type === 'casinos' &&
            intl.formatMessage({ id: 'screen.catagories.casinos.title' })}
          {type === 'esports' &&
            intl.formatMessage({ id: 'screen.catagories.esports.title' })}
          {type === 'sports' &&
            intl.formatMessage({ id: 'screen.catagories.sports.title' })}
          {type === 'slots' &&
            intl.formatMessage({ id: 'screen.catagories.slots.title' })}
        </ArticleTitle>

        <ArticleDesc>
          {type === 'casinos' &&
            intl.formatMessage({ id: 'screen.catagories.casinos.desc' })}
          {type === 'esports' &&
            intl.formatMessage({ id: 'screen.catagories.esports.desc' })}
          {type === 'sports' &&
            intl.formatMessage({ id: 'screen.catagories.sports.desc' })}
          {type === 'slots' &&
            intl.formatMessage({ id: 'screen.catagories.slots.desc' })}
        </ArticleDesc>
      </Article>

      <ArticleCover>
        {type === 'slots' && (
          <ImageResponsive
            source="catagorie-cover--slot.png"
            alt="ALLTOP Promotions"
            fadeIn
          />
        )}

        {type === 'casinos' && (
          <ImageResponsive
            source="catagorie-cover--casino.png"
            alt="ALLTOP Promotions"
            fadeIn
          />
        )}

        {type === 'sports' && (
          <ImageResponsive
            source="catagorie-cover--sport.png"
            alt="ALLTOP Promotions"
            fadeIn
          />
        )}

        {type === 'esports' && (
          <ImageResponsive
            source="catagorie-cover--esport.png"
            alt="ALLTOP Promotions"
            fadeIn
          />
        )}
      </ArticleCover>

      <Providers>
        {type === 'casinos' && providersCasinoList}
        {type === 'esports' && providersEsportsList}
        {type === 'sports' && providersSportsList}
        {type === 'slots' && providersSlotsList}
      </Providers>
    </State>
  );
};

Applications.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Applications;
